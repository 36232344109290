<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-dialog v-model="dialog" width="400">
        <template v-slot:activator="{}">
          <v-btn
            icon
            small
            v-on="on"
            @click="edit()"
            :disabled="disabled"
            v-if="!hidden"
          >
            <v-icon small>$edit</v-icon>
          </v-btn>
        </template>
        <v-card id="cardEdit">
          <v-card-title>
            {{ $t("edit", { name: $tc("appointments.session") }) }}
          </v-card-title>
          <div class="close">
            <v-btn icon @click="dialog = false" small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <form
            action
            data-vv-scope="add-sesion-form"
            @submit.prevent="validateForm('add-sesion-form')"
          >
            <v-card-text>
              <v-row justify="center">
                <v-col cols="12" class="text-center">
                  <v-checkbox
                    v-model="editSession.previos_date"
                    label="Poner una fecha anterior"
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-date-picker
                  first-day-of-week="1"
                  v-model="editSession.date"
                  color="primary"
                  no-title
                  :min="today"
                  :allowed-dates="allowedDates"
                  @change="fetchBoxes"
                ></v-date-picker>
                <v-col cols="1" v-if="editSession.box_id && box_active"></v-col>

                <v-col cols="6" md="4" class="py-0">
                  <label v-text="$t('appointments.sessions.time_start')" />
                  <v-autocomplete
                    :items="hours"
                    v-model="editSession.time"
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect('add-sesion-form.time_start')
                    "
                    data-vv-name="time_start"
                    hide-details
                    outlined
                    v-bind:class="{ 'error--text': timeError || horarioError }"
                    @change="fetchBoxes"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="4" class="py-0">
                  <label v-text="$t('appointments.sessions.time_end')" />
                  <v-autocomplete
                    :items="hours"
                    v-model="editSession.endTime"
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('add-sesion-form.time_end')"
                    data-vv-name="time_end"
                    hide-details
                    outlined
                    v-bind:class="{ 'error--text': timeError || horarioError }"
                    @change="fetchBoxes"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="1" v-if="editSession.box_id && box_active"></v-col>
                <v-col
                  cols="4"
                  class="py-0"
                  v-if="editSession.box_id && $store.getters['auth/isStudio']"
                >
                  <label v-text="$t('appointments.sessions.box_id')" />
                  <v-select
                    :items="boxes"
                    v-model="editSession.box_id"
                    dense
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    :error-messages="errors.collect('add-sesion-form.box_id')"
                    data-vv-name="box_id"
                    hide-details
                    outlined
                    item-value="id"
                    item-text="name"
                  >
                    <template slot="item" slot-scope="data">
                      <v-icon
                        v-if="
                          !data.item.boxes_tattooer_count ||
                            data.item.boxes_tattooer_count == 0
                        "
                        color="success"
                        class="mr-2"
                      >
                        mdi-check</v-icon
                      >
                      <v-icon v-else color="red" class="mr-2">
                        mdi-close</v-icon
                      >
                      {{ data.item.name }}
                    </template>
                  </v-select>
                </v-col>
                <!-- <v-col cols="5" md="4" class="py-0">
                  <label v-text="$t('appointments.sessions.price')" />
                  <v-text-field
                    type="number"
                    v-on:keypress="isNumber($event)"
                    v-model="editSession.price"
                    dense
                    hide-details
                    outlined
                    disabled
                    suffix="€"
                  ></v-text-field>
                </v-col>-->
                <v-col cols="1" v-if="editSession.box_id && box_active"></v-col>

                <v-col cols="10" class="py-0" v-if="customers.length > 1">
                  <label>{{ $tc("customer", 2) }}</label>
                  <v-select
                    outlined
                    dense
                    multiple
                    :items="customers"
                    item-value="id"
                    v-model="editSession.customers"
                    hide-details
                    :item-text="
                      (x) => `${x.user.first_name} ${x.user.last_name || ''}`
                    "
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index <= 0">
                        {{ item.user.first_name }} {{ item.user.last_name }}
                      </span>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption ml-1"
                      >
                        (+{{ editSession.customers.length - 1 }} más)
                      </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row
                v-if="horarioError"
                class="py-0"
                style="width: 100%; margin: 0 auto"
              >
                <v-col
                  class="
                    login_error
                    white--text
                    pa-1
                    text-center
                    rounded-xl
                    my-0
                  "
                  >Esta fuera del horario establecido</v-col
                >
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-text="$t('cancel')"
                @click="dialog = false"
                outlined
                style="height: 25px"
              />
              <v-btn
                type="submit"
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                {{ $t("save", { name: $t("") }) }}
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </template>
    {{ $t("edit", { name: "" }) }}
  </v-tooltip>
</template>
<script>
import { mapActions } from "vuex";
import * as moment from "moment";
export default {
  name: "EditSession",
  props: ["session", "appointment", "disabled", "customers", "hidden"],
  data() {
    return {
      dialog: false,
      editSession: {
        date: undefined,
        time: undefined,
        endTime: undefined,
        box_id: undefined,
      },
      today: null,
      timeStudio: [],
      dates: [],
      datesTattooer: [],
      datesRelation: [],
      boxes: [],
    };
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    this.fetchTimeStudio();
    if (this.$store.getters["auth/isStudio"]) {
      this.fetchAll();
    } else {
      this.dates = this.appointment.studio.vacations;
    }
    this.fetchAllVacationsTattooer(
      this.appointment.tattooer.id,
      this.appointment.studio.id
    );
  },
  computed: {
    box_active() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "box_active"
      );
      if (s) return s.value === "1" && this.appointment.studio.user.plan.boxes;
      return false;
    },
    hours() {
      let mins = [":00", ":15", ":30", ":45"];
      let hours = [];
      let first = parseInt(this.timeFrom.split(":")[0]);
      let last = parseInt(this.timeTo.split(":")[0]);
      for (let x = first; x < last; x++) {
        mins.forEach((y) => {
          if (x < 10) hours.push("0" + x + y);
          else hours.push(x + y);
        });
      }

      if (hours.length) {
        hours.push(last + ":00");
        if (this.timeTo.split(":")[1] == "30") hours.push(last + ":30");
      }
      return hours;
    },
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
      }
      return "00:00";
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;
      }
      return "24:00";
    },
    horarioError() {
      if (
        this.editSession.date &&
        this.editSession.time &&
        this.editSession.endTime
      ) {
        let dh = this.timeTable[this.dayOfWeek(this.editSession.date)];
        let error = true;
        dh.forEach((h) => {
          if (h[0] <= this.editSession.time && h[1] >= this.editSession.endTime)
            error = false;
        });
        return error;
      }
      return false;
    },
    timeError() {
      if (
        this.editSession.time !== undefined &&
        this.editSession.endTime !== undefined
      ) {
        let s = this.editSession.time.split(":");
        let e = this.editSession.endTime.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] > e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
    timeTable() {
      if (this.appointment.tattooer) {
        let dh = this.appointment.tattooer.tattooer_studios.find((x) => {
          return x.studio_id === this.appointment.studio_id;
        });
        console.log("dh", dh);
        if (dh && dh.tattooer_timetable) return dh.tattooer_timetable;
      }
      return this.timeStudio;
    },
  },
  methods: {
    ...mapActions("boxes", ["getBoxes"]),
    fetchBoxes() {
      //console.log("boxes");
      this.getBoxes({
        pagination: null,
        filters: {
          date: this.editSession.date,
          start_time: this.editSession.time,
          end_time: this.editSession.endTime,
          studio_id: this.appointment.studio.id,
        },
      }).then((boxes) => {
        this.boxes = boxes.data;
      });
    },
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    edit() {
      //console.log(this.session);
      let today = new Date();
      let edit = new Date(this.$props.session.date.substring(0, 10));
      edit.setDate(edit.getDate() + 1);
      if (edit.getTime() < today.getTime()) {
        this.$alert(
          "Esta sesion ya ha pasado y no se puede editar. Para cambiarla eliminela i cree una nueva"
        );
        return;
      }
      this.editSession.date = this.$props.session.date.substring(0, 10);
      this.editSession.time = this.$props.session.date.substring(11, 16);
      this.editSession.endTime = this.$props.session.endDate
        ? this.$props.session.endDate.substring(11, 16)
        : this.editSession.time;
      this.editSession.price = this.$props.session.sesion_price;
      if (this.$props.session.boxes_tattooer) {
        let c = this.$props.session.boxes_tattooer;
        console.log(c);
        console.log(c.box_id);

        this.editSession.box_id = c.box_id;
        console.log(this.editSession.box_id);
      }
      console.log(this.editSession);
      //console.log(this.editSession);
      this.editSession.customers = this.$props.session.customers.map(
        (x) => x.id
      );
      this.dialog = true;
      this.fetchBoxes();
    },
    ...mapActions("studios", ["getStudioTattooersByDate", "getStudio"]),
    ...mapActions("tattooers", ["getTattooer"]),
    fetchTimeStudio() {
      let studio_id = null;
      if (this.$store.getters["auth/isStudio"]) {
        this.getStudio(this.$store.state.auth.user.studio.id).then((data) => {
          ////console.log(data);
          this.timeStudio = data.timetable;
          ////console.log(this.timeStudio);
        });
        this.getTattooer(this.appointment.tattooer.id).then((data) => {
          console.log(data);
          if (data.studios.length) {
            let y = data.studios.find(
              (x) => x.id === this.$store.state.auth.user.studio.id
            );
            this.datesRelation[0] = y.pivot.from;
            this.datesRelation[1] = y.pivot.to;
          }
        });
      } else if (this.$store.getters["auth/isTattooer"]) {
        ////console.log(this.$store.state.auth.user);
        this.getTattooer(this.$store.state.auth.user.tattooer.id).then(
          (data) => {
            //console.log(data);
            if (data.tattooer_studios.length) {
              this.timeStudio = data.tattooer_studios[0].studio.timetable;
            }
            if (data.studios.length) {
              let y = data.studios.find(
                (x) => x.id === this.appointment.studio.id
              );
              this.datesRelation[0] = y.pivot.from;
              this.datesRelation[1] = y.pivot.to;
            }
          }
        );
      }
    },
    ...mapActions("appointments", ["editSesion"]),
    async validateForm(scope) {
      this.errors.clear();
      //console.log("validando");
      //console.log(this.editSession);
      this.$validator.validateAll(scope).then((result) => {
        if (result && !this.timeError && !this.horarioError) {
          this.editSesion({
            appointment_id: this.session.appointment_id,
            session_id: this.session.id,
            formData: {
              ...this.editSession,
              timezone: -new Date().getTimezoneOffset(),
            },
          }).then((data) => {
            if (data.success) {
              this.dialog = false;
              this.editSession = {};
              this.$alert(this.$t("save_ok"));
            } else {
              switch (data.type) {
                case "box":
                  this.$alert(this.$t("event_save_cant"));
                  break;
                case "artist":
                default:
                  this.$alert(this.$t("session_overlap"));
                  break;
              }
            }
            this.$emit("update");
          });
        }
      });
    },

    ...mapActions("close_days", ["getAll"]),
    fetchAll() {
      this.getAll().then((data) => {
        if (data) {
          this.dates = data;
          console.log(this.dates);
        }
      });
    },
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      console.log(tattooer);
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            console.log(this.datesTattooer);
          }
        }
      );
    },
    allowedDates(date) {
      let a = this.dates.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });
      console.log(a);

      if (a) {
        return false;
      }
      let t = this.datesTattooer.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (t) {
        //console.log(t);
        return false;
      }

      let rf = this.datesRelation[0] > date;

      if (rf) {
        console.log(rf);
        return false;
      }

      let rt = this.datesRelation[1] < date;

      if (rt) {
        console.log(rt);
        return false;
      }
      if (this.timeStudio && this.timeStudio.length > 0) {
        let a = this.timeTable[this.dayOfWeek(date)];
        if (a) {
          return a.length > 0;
        }
      }
      if (!rt && !rf) {
        return true;
      }
      return false;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
  },
};
</script>

<style scoped lang="sass">
#cardEdit

label
  display: block
  height: 25px
</style>
